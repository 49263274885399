.rank__card {
	// padding: 8px 12px 8px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.6);
	color: #fff;
	border-radius: 16px 8px;
	overflow: hidden;
	min-width: 313px;
	height: 40px;
	backdrop-filter: blur(10px);
	margin: 0 auto;

	@media (min-width: 768px) {
		max-width: 678px;
		height: 54px;
		margin: initial auto;
	}

	&.top {
		&>div:first-child {
			background: rgba(44, 42, 41, 1);
		}
	}

	&>div:first-child {
		background: rgba(237, 28, 36, 1);
		padding: 10px;
		// @include font($font-oswald, 18px, #fff, 500, 28px, normal);
		min-width: 62px;

		@media (min-width: 768px) {
			font-size: 22px;
			line-height: 34px;
		}
	}

	&>div:nth-child(2) {
		padding: 8px 8px 8px 12px;
		flex-grow: 2;
		// @include font($font-oswald, 16px, #fff, 600, 21px, normal);

		@media (min-width: 768px) {
			font-size: 20px;
			line-height: normal;
		}
	}

	&>div:last-child {
		padding: 8px 12px 8px 8px;
		// @include font($font-oswald, 20px, #fff, 500, 32px, normal);
		display: flex;
		align-items: center;
		gap: 4px;

		@media (min-width: 768px) {
			font-size: 24px;
			line-height: normal;
		}
	}

	.status {
		position: relative;
		width: 16px;
		height: 16px;
		padding: 2.6px 3.3px;
	}
}