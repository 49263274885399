/* .question span, */
.question strong {
    /* color: var(--text_1) */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.text::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.text {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}