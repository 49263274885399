.content {
	background: white;
	border-radius: 16px;
	padding: 0;
	filter: drop-shadow(0px 2px 34px rgba(0, 0, 0, 0.14));
	animation-duration: 400ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	animation-fill-mode: forwards;
	will-change: transform, opacity;

	&[data-state='open'] {
		&[data-side='top'] {
			animation-name: slideDownAndFade;
		}

		&[data-side='right'] {
			animation-name: slideLeftAndFade;
		}

		&[data-side='bottom'] {
			animation-name: slideUpAndFade;
		}

		&[data-side='left'] {
			animation-name: slideRightAndFade;
		}
	}

	&_sticky {
		transform: rotate(180deg) !important;
		top: -30px !important;
		left: 320px !important;
		position: fixed !important;
	}
}

// .content_inner {
// 	padding: 20px;
// }

.arrow {
	&::after {
		content: '';
		position: absolute;
		height: 10px;
	}
}

@keyframes slideUpAndFade {
	0% {
		opacity: 0;
		transform: translateY(2px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideRightAndFade {
	0% {
		opacity: 0;
		transform: translateX(-2px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideDownAndFade {
	0% {
		opacity: 0;
		transform: translateY(-2px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideLeftAndFade {
	0% {
		opacity: 0;
		transform: translateX(2px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}