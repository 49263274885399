.leaderboard {
	&__prizes {
		margin: 20px 10px;
		padding: 15px 0;
		border-radius: 10px;
		position: relative;
		z-index: 1;
		overflow: hidden;

		@media (min-width: 768px) {
			width: calc(100% - 401px);
			margin: 0;
			padding: 65px 0 15px 0;
			display: flex;
		}

		h2 {
			// @include font($font-oswald, 32px, #fff, 500, 38px, normal);
			text-transform: uppercase;
			margin-bottom: 8px;

			@media (min-width: 768px) {
				// font-size: 40px;
				// line-height: 48px;
				margin-bottom: 16px;
			}
		}

		&__sub {
			// margin-bottom: 32px;
			line-height: 24px;
			max-width: 758px;
			margin: 0 auto 32px;

			@media (min-width: 768px) {
				margin-bottom: 40px;
				// line-height: 24px;
			}
		}

		&__list {
			display: flex;
			list-style: none;
			align-items: center;
			justify-content: center;
			gap: 16px;
			max-width: 343px;
			margin: 0 auto;

			@media (min-width: 768px) {
				max-width: 755px;
				gap: 20px;
			}

			&>li:nth-child(2) {
				order: 1;
			}

			&>li:first-child {
				order: 2;

				&>div {
					width: 139px;
					max-width: 139px;
					min-height: 188px;
					position: relative;

					@media (min-width: 768px) {
						// padding: 38px 16px;
						max-width: 180px;
						width: 180px;
						height: 100%;
					}

					&::after {
						content: '';
						position: absolute;
						top: -22px;
						width: 100%;
						height: 165px;
						background: url('/assets/leaderboard/light-rays.png');
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						z-index: 0;

						// @media (min-width: 768px) {
						// 	background: url('/assets/leaderboard/light-rays-desk.png');
						// 	height: 367px;
						// 	top: 0;
						// 	background-position: bottom;
						// }
					}

					// &>div:first-child {
					// 	font-size: 14px;
					// 	line-height: 19px;

					// 	@include response(tab) {
					// 		font-size: 16px;
					// 		line-height: 25px;
					// 	}
					// }

					// &>div:nth-child(2) {
					// 	font-size: 12px;
					// 	line-height: 16px;
					// 	max-width: unset;
					// 	// max-width: 10ch;

					// 	@include response(tab) {
					// 		font-size: 20px;
					// 		line-height: normal;
					// 		// max-width: unset;
					// 	}
					// }

					&>div:last-child {
						width: 100px;
						min-height: 100px;
						margin: 0 12px;
						z-index: 5;

						@media (min-width: 768px) {
							width: 165px;
							height: 165px;
						}
					}
				}
			}

			&>li:last-child {
				order: 3;
			}

			// gap: 16px;
		}

		.condition {
			// @include font($font-roboto, 14px, #b1c8cd, 400, 20px, normal);
		}
	}
}