$iqos_tablet: 1024px;
$iqos_desktop: 1119px;

.slider_button {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 3;

  @media screen and (min-width: $iqos_desktop) {
    padding: 0 40px;
  }

  &.right {
    right: 0;
    left: auto;
  }

  svg {
    width: 56px;
    height: 56px;

    path {
      fill: black;
    }
  }

  &.disabled_btn {
    opacity: 0.25;
    cursor: not-allowed;
  }
}