.audio_player {
  padding: 0;
  box-shadow: none;

  .rhap_main {
    gap: 20px;

    @media (max-width: 767px) {
      gap: 30px;
    }
  }

  .rhap_time {
    color: white;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .rhap_progress-container {
    &:hover {
      .rhap_progress-bar .rhap_progress-indicator {
        opacity: 1;
      }
    }
    .rhap_progress-bar {
      background-color: rgba(255, 255, 255, 0.25);

      .rhap_progress-indicator {
        opacity: 0;
        width: 11px;
        height: 11px;
        top: -3px;
        background-color: var(--icon_4);
        transition: opacity 0.2s ease-in-out;
      }
      .rhap_progress-filled {
        background-color: var(--icon_1);
      }
    }
  }

  .rhap_controls-section {
    margin-top: 0;

    .rhap_main-controls {
      .rhap_rewind-button,
      .rhap_forward-button {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .rhap_main-controls-button {
        width: 40px;
        height: 40px;
        font-size: 40px;
      }
      .rhap_play-pause-button {
        width: 64px;
        height: 64px;
        font-size: 64px;
      }
    }

    .rhap_volume-controls {
      justify-content: flex-start;

      .rhap_volume-container {
        gap: 20px;
        flex: 0 1 120px;
        @media (max-width: 767px) {
          flex-basis: auto;
        }

        .rhap_volume-bar-area {
          @media (max-width: 767px) {
            display: none;
          }

          &:hover {
            .rhap_volume-bar .rhap_volume-indicator {
              opacity: 1;
            }
          }
          .rhap_volume-bar {
            background-color: rgba(255, 255, 255, 0.25);
            .rhap_volume-indicator {
              opacity: 0;
              width: 11px;
              height: 11px;
              top: -3px;
              z-index: 3;
              background-color: var(--icon_4);
              transition: opacity 0.2s ease-in-out !important;
            }
            .rhap_volume-filled {
              background-color: var(--icon_1);
            }
          }
        }

        .rhap_volume-button {
          flex-basis: 40px;
          width: 40px;
          height: 40px;
          font-size: 40px;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .rhap_additional-controls {
      justify-content: flex-end;
      gap: 20px;

      & button {
        width: 40px;
        height: 40px;
        font-size: 40px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
