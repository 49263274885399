.DetailDescription {
    font-size: 14px;
    line-height: 24px;
    color: var(--text_2);
}

.DetailDescription strong{
    font-family: var(--font-family-7) !important;
    font-weight: 700 !important;
}

.DetailDescription a{
    text-decoration: underline !important;
    color: blue !important;
}

.DetailDescription ul{
    list-style: disc;
}

.DetailDescription ol{
    list-style: decimal;
}

.DetailDescription ul,
.DetailDescription ol {
    padding-left: 25px;
}

@media only screen and (min-width: 1024px) {
    .DetailDescription {
        font-size: 16px;
        line-height: 28px;
    }
}