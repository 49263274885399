.content.info {
	transform: unset;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 100%;
	border-radius: 10px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	top: unset;
	min-height: 50%;
	height: fit-content;
	padding: 12px 0 0 0;

	@media (min-width: 768px) {
		overflow-y: auto;
		top: 50%;
		left: 50%;
		bottom: auto;
		transform: translate(-50%, -50%);
		max-width: 600px;
		min-height: 200px;
		background: none;
	}
}

.content_inner {
	overflow-y: auto;
	max-height: 100%;
	padding: 24px;
	padding-bottom: 96px;

	@media (min-width: 768px) {
		padding: 0;
		padding-bottom: 96px;
		overflow: hidden;
	}
}

.drag_line {
	position: absolute;
	top: 12px;
	left: 50%;
	transform: translateX(-50%);
	width: 32px;
	height: 3px;
	background: #dadada;
	border-radius: 12px;
}

.drag_area {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 31px;
	z-index: 2;
}