.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
}

.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
}

.content {
	position: fixed;
	top: 50%;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, -50%);
	width: 100vw;
	background-color: var(--background_3);
	border-radius: 5px;
	padding: 20px;
	z-index: 50;
	max-height: 100vh;
	overflow: visible;

	@media (min-width: 768px) {
		max-width: 600px;
	}
}