.bottom {
	margin: 20px 10px;
	background-color: crimson;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	border-radius: 10px;
	overflow: hidden;

	@media (min-width: 768px) {
		margin: 0;
		width: 410px;
	}

	.btnAspace {
		padding: 8px 24px;
		border-radius: 10px;
		background-color: #fff !important;
		color: #2C2A29;
		width: 100%;
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 250ms ease-in-out;

		@media (min-width: 768px) {
			padding: 13px 24px;
			font-size: 16px;
			width: 260px;
			border-radius: 10px;
		}

		&:hover,
		&:focus {
			transform: scale(1.05);
		}
	}

	svg {
		width: 40px;

		@media (min-width: 768px) {
			width: 46px;
		}

		// fill: #000 !important;
	}
}