.envelope {
  animation: slide-up 1s linear both;

  @keyframes slide-up {
    from {
      transform: translate(-50%, 0);
    }

    to {
      transform: translate(-50%, -50%);
    }
  }
}