$iqos_tablet: 1024px;
$iqos_desktop: 1119px;

.swiper_header_slogan {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #16becf;
	padding: 4px 0;
	z-index: 5;

	color: #fffdfb;
	text-align: center;
	font-family: 'IQOSW04-Bold';
	font-size: 20px;
	font-weight: 700;
	line-height: 90px;
	text-transform: capitalize;

	& strong{
		font-weight: 900;
	}

	@media screen and (min-width: $iqos_desktop) {
		padding: 14px 0;
		font-size: 40px;
		line-height: 100px;
	}

	&_dark {
		color: black;
	}
}

.swiper_slide_wrapper {
	position: relative;
	width: 100%;
	height: 100vh;
}

.swiper_slide_content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.swiper_slide_mainContent {
	z-index: 1;
	color: white;
	padding: 106px 16px 16px;

	display: flex;
	flex-direction: column;

	@media screen and (min-width: $iqos_desktop) {
		padding: 130px 144px 0px;
	}

	&_dark {
		color: black;
	}
}

.swiper_slide_logo {
	margin-bottom: 24px;

	@media screen and (min-width: $iqos_desktop) {
		align-self: center;
		margin-bottom: 56px;
	}
}

.logo {
	opacity: 0;
}

.swiper_slide_textWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;

	padding-bottom: 40px;

	@media screen and (min-width: $iqos_desktop) {
		justify-content: center;
		padding-bottom: 56px;
		margin-top: 0px;
	}
}

.swiper_slide_text {
	@media screen and (min-width: $iqos_desktop) {
		margin-bottom: 40px;
	}
}

.swiper_slide_title {
	z-index: 1;
	position: relative;
	margin-bottom: 8px;

	font-family: 'IQOSW04-Bold';
	font-size: 24px;
	font-weight: 700;
	line-height: normal;

	@media screen and (min-width: $iqos_tablet) {
		font-size: 40px;
	}

	@media screen and (min-width: $iqos_desktop) {
		font-size: 48px;
		line-height: 48px;
		max-width: 50%;
	}
}

.swiper_slide_descWrapper {
	position: relative;

	@media screen and (min-width: $iqos_desktop) {
		max-width: 50%;
	}
}

.swiper_slide_desc {
	position: relative;
	z-index: 1;
	font-family: 'IQOSW04-Regular';
	font-size: 24px;
	line-height: 36.77px;
	text-align: left;

	@media screen and (min-width: $iqos_tablet) {
		font-size: 24px;
	}

	@media screen and (min-width: $iqos_desktop) {

		font-size: 48px;
		font-weight: 700;
		line-height: 73.54px;
		text-align: left;

	}
}

.swiper_slide_descBlur {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	border-radius: 359px;
	opacity: 0.9;
	background: #34303d;
	mix-blend-mode: multiply;
	filter: blur(45px);

	@media screen and (min-width: $iqos_desktop) {
		width: 95%;
		height: 110%;
		transform: translate(-20px, -15px);
	}
}

.swiper_add_info {
	margin-bottom: 10px;
	color: black;
	font-family: 'IQOSW04-Regular';
	font-size: 8px;
	font-weight: 400;
	line-height: normal;

	@media screen and (min-width: $iqos_tablet) {
		font-size: 16px;
	}

	&_desktop {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 3;

		color: black;
		font-family: 'IQOSW04-Regular';
		font-size: 12px;
		font-weight: 400;
		line-height: normal;

		padding: 0 48px 64px;

		&_dark {
			color: black;
		}
	}

	&_dark {
		color: black;
	}
}

.swiper_slide_button {
	padding: 0 23px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: $iqos_desktop) {
		padding: 0;
		flex-direction: row;
	}
}

.swiper_btn {
	z-index: 1;
	position: relative;

	cursor: pointer;

	border-radius: 64px;
	margin-bottom: 6px;
	padding: 12px;
	background-color: white;
	color: black;
	text-align: center;

	font-family: 'IQOSW04-Bold';
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	width: 296px;

	@media screen and (min-width: $iqos_desktop) {
		width: 192px;
	}

	&_dark {
		color: white;
		background-color: black;
	}

	&_b {
		background-color: transparent;
		color: white;
		margin-bottom: 0;

		@media screen and (min-width: $iqos_tablet) {
			max-width: 728px;
		}

		@media screen and (min-width: $iqos_desktop) {
			margin-bottom: 6px;
		}

		&_dark {
			background-color: transparent;
			color: black;
		}
	}
}

@media screen and (min-width: 1024px) and (max-height: 650px) {
	.swiper_slide_mainContent {
		padding: 60px 120px 0px;
	}

	.swiper_header_slogan {
		font-size: 24px;
	}

	.swiper_slide_logo {
		margin-bottom: 0;
	}

	.swiper_slide_title {
		font-size: 36px;
	}

	.swiper_slide_desc {
		font-size: 48px;
		line-height: 73.54px;
		text-align: left;
	}

	.swiper_add_info_desktop {
		font-size: 12px;
	}

	.swiper_btn {
		padding: 8px;
		font-size: 14px;
		line-height: 20px;
	}
}

@media screen and (max-width: 1024px) and (max-height: 667px) {
	.swiper_slide_logo {
		margin-bottom: 8px;
	}

	.swiper_slide_title {
		margin-bottom: 4px;
		font-size: 20px;
	}

	.swiper_slide_desc {
		font-size: 24px;
		line-height: 36.77px;
		text-align: left;

	}

	.swiper_add_info {
		font-size: 8px;
		line-height: 10px;
	}

	.swiper_btn {
		padding: 12px;
		font-size: 16px;
		line-height: 24px;
		width: 296px;
	}
}

.swiper_slide_container{
	& strong{
		font-weight: 900;
	}
}