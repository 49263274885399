.leaderboard {
	&__ranks {
		position: relative;
		margin: 20px 10px;
		padding: 20px 0;
		border-radius: 10px;
		overflow: hidden;

		@media (min-width: 768px) {
			margin: 15px;
			padding: 40px 0;
		}

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 762px;
			z-index: -1;
			bottom: 10px;

			@media (min-width: 768px) {
				height: 955px;
				bottom: -12px;
			}
		}

		&__header {
			padding: 10px 24px;
			text-align: center;
			width: 100%;
			background: rgba(0, 0, 0, 0.6);
			/* blur */
			backdrop-filter: blur(10px);
			border-radius: 16px 8px;
			overflow: hidden;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				background: url('/assets/leaderboard/accent-a.png');
				background-size: cover;
				background-repeat: no-repeat;
				width: 49px;
				height: 36px;

				@media (min-width: 768px) {
					background: url('/assets/leaderboard/accent-a-lg.png');
					height: 45px;
				}
			}

			span {
				color: #fff;
				font-weight: 700;
			}
		}
	}

	&__rank {
		padding: 12px 20px;
		background: rgba(237, 28, 36, 0.3);
		margin-bottom: 15px;

		@media (min-width: 768px) {
			margin-bottom: 24px;
		}
	}

	&__list {
		padding: 0 16px;
		height: 220px;
		overflow-y: scroll;
		overflow-x: hidden;

		/* Chrome, Edge, and Safari */
		&::-webkit-scrollbar {
			width: 7px;
		}

		&::-webkit-scrollbar-track {
			background: #FFFFFF;
			border-radius: 50px;
			margin: 0 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #BFBFBE;
			border-radius: 50px;
		}

		@media (min-width: 768px) {
			max-width: 758px;
			height: 310px;
			margin: 0 auto;
		}

		ol {
			list-style: none;
		}

		li {
			margin: 0 auto;
			max-width: 758px;
		}

		li:not(:last-child) {
			margin-bottom: 5px;

			@media (min-width: 768px) {
				margin-bottom: 10px;
			}
		}
	}
}

.rank__container {
	// max-width: 758px;
	padding: 0 20px 15px;
	margin: 0 auto;
	color: #fff;

	@media (min-width: 768px) {
		padding: 0 0 24px;
	}
}