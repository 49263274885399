.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    /* width: calc(100% - 8px); */
    margin: 0 auto;
    border-radius: 5px;
    height: 8px;
    background: var(--cta_4);
    outline: none;
    /* opacity: 0.7; */
    /* -webkit-transition: .2s; */
    /* transition: opacity .2s; */
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: 7px solid var(--cta_1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--cta_3);
    cursor: pointer;
    position: relative;
}

.slider::-moz-range-thumb {
    border: 7px solid var(--cta_1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--cta_3);
    cursor: pointer;
    position: relative;
}
