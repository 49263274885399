.leaderboard__header {
    text-align: center;
    position: relative;

    @media (min-width: 768px) {
        text-align: left;
        width: calc(100% - 40px);
        margin: 15px auto;
    }

    h1 {
        text-transform: uppercase;
        margin-bottom: 16px;

        span {
            color: #E00007;
        }
    }

    &>p:last-child {
        max-width: 34ch;
        margin: 0 auto;
    }

    &__sub {
        margin-bottom: 4px;
    }
}

.leaderboard__btn {
    // button {
    padding: 14px 25px;
    margin: 0 auto 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 43px;
    width: 100%;
    max-width: 200px;
    background-color: #e00007;

    &:hover,
    &:focus {
        transform: scale(1.05);
    }
}