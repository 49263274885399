.loading_overlay {
	position: absolute;
	z-index: 2;
	inset: 0;
	background: rgba(255, 255, 255, 0.65);
	display: flex;
	align-items: center;
	justify-content: center;
}

.fixed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
}