$iqos_tablet: 1024px;
$iqos_desktop: 1119px;

.video_list_slider_dots {
  display: flex;
  position: absolute;
  z-index: 3;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 16px;

  @media screen and (min-width: $iqos_desktop) {
    top: auto;
    bottom: 77px; // GHW 56px + 21px
    justify-content: center;
  }
}

.video_list_slider_dot {

  &_wrapper {
    position: relative;
    width: 100%;
    padding: 0 8px;

    @media screen and (min-width: $iqos_desktop) {
      width: 8px;
      padding: 0;
      margin-right: 8px;

    }

    &.active {
      @media screen and (min-width: $iqos_desktop) {
        width: 40px;
      }
    }
  }

  width: 100%;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 999px;
  transition: width 0.2s ease-in-out,
  height 0.2s ease-in-out;

  @media screen and (min-width: $iqos_desktop) {
    height: 8px;
    width: 8px;
    background: rgba(255, 255, 255, 0.5);

    &.active {
      width: 40px;
    }
  }

  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 50%;
    background: #ffffff;

    @media screen and (min-width: $iqos_desktop) {
      width: 20px;
    }

    &.dark_dot {
      @media screen and (min-width: $iqos_desktop) {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.dark_dot {
  @media screen and (min-width: $iqos_desktop) {
    background: rgba(0, 0, 0, 0.25);
  }
}