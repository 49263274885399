.container::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    /* Customize the scrollbar width */
}

.container::-webkit-scrollbar-track {
    /* Customize the scrollbar track */
    border-radius: 8px;
    background: var(--cat_2);
}

.container::-webkit-scrollbar-thumb {
    /* Customize the scrollbar thumb */
    background: var(--cta_1);
    border-radius: 8px;
    border: 3px solid var(--cat_2);
}