/* The container */
.container {
    display: flex;
    gap: 5px;
    position: relative;
    /* align-items: center; */
    /* padding-left: 35px;
    margin-bottom: 12px; */
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    /* font-weight: 700; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media only screen and (min-width: 1024px) {
    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: relative;
    top: 0;
    left: 0;
    min-width: 24px;
    height: 24px;
    width: 24px;
    border-radius: 5px;
    background-color: #eee;
    border: 1px solid transparent;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background-color: var(--icon_1);
    border-color: var(--icon_1);
}

/* .container input:checked~.checkmark{
    border: 1px;
    border-color:red;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    position: absolute;
    left: 7px;
    top: 1px;
    /* transform: translate(-50%, -50%); */
    width: 8px;
    height: 16px;
    /* border-radius: 5px; */
    /* background-color: var(--icon_1); */
    border: solid var(--background_3);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}