/* ml-[25px] list-disc pb-[25px] */

.description ul{
    margin-left: 25px;
    list-style: disc;
    /* padding-bottom: 25px; */
}

.description ol{
    margin-left: 25px;
    list-style: decimal;
    /* padding-bottom: 25px; */
}

@media only screen and (min-width: 1024px) {
    .description ul{
        padding-bottom: 0;
    }
}

.wrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    /* Customize the scrollbar width */
}

.wrapper::-webkit-scrollbar-track {
    /* Customize the scrollbar track */
    border-radius: 20px;
    background: var(--cat_2);
}

.wrapper::-webkit-scrollbar-thumb {
    /* Customize the scrollbar thumb */
    background: var(--cta_1);
    border-radius: 20px;
    border: 3px solid var(--cat_2);
}