.DetailTnCText {
    font-size: 14px;
    line-height: 28px;
    color: var(--text_2);
}


.DetailTnCText ul{
    list-style: disc;
}

.DetailTnCText ol{
    list-style: decimal;
}

.DetailTnCText ul,
.DetailTnCText ol {
    padding-left: 25px;
}