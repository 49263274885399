$scroll-bar-size: 10px;

.scroll_area {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: inherit;
	
	&_sticky {
		transform: rotate(180deg);
	}
}

.viewport {
	width: 100%;
	height: 100%;
	border-radius: inherit;
}

.scroll_bar {
	display: flex;
	// ensures no selection
	user-select: none;
	// disable browser handling of all panning and zooming gestures on touch devices
	touch-action: none;
	padding: 2;
	background: white;
	transition: background 160ms ease-out;
	&:hover {
		background: gray;
	}
	&[data-orientation='vertical'] {
		width: 10px;
	}
	&[data-orientation='horizontal'] {
		flex-direction: column;
		height: 10px;
	}
}

.thumb {
	flex: 1;
	background: grey;
	border-radius: 10px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		min-width: 44;
		min-height: 44;
	}
}

.corner {
	border-radius: inherit;
}
