/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 21px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 16px;
    width: 16px;
    background: var(--cta_2);
}

.checkmark.disabled{
    background: var(--cta_4);
}

.checkmark.isCenter {
    top: 50%;
    transform: translateY(-50%);
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
    /* background-color: var(--cta_1); */
    /* opacity: 0.5; */
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
    background: var(--icon_1);
}

.container.disabled input:checked~.checkmark {
    background: var(--cta_4);
    border-color: var(--icon_3);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:before {
    content: "";
    position: absolute;
    display: none;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
    display: block;
}
.container input:checked~.checkmark:before {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 4.5px;
    top: 2.4px;
    width: 5.5px;
    height: 8px;
    /* border-width: 0 2px 2px 0 !important; */
    /* border: solid var(--cta_2); */
    background: var(--cta_2);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
}

.container .checkmark:before {
    left: 4.5px;
    top: 0.3px;
    width: 5.5px;
    height: 8px;
    /* border-width: 0 2px 2px 0 !important; */
    /* border: solid var(--cta_2); */
    background: var(--icon_1);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 2;
}

.container.disabled .checkmark:after {
    /* border-width: 0 2px 2px 0 !important; */
    /* border: solid var(--icon_3); */
    background: var(--icon_3);
    left: 4.5px;
    top: 2.4px;
    width: 5.5px;
    height: 8px;
    /* border: solid var(--cta_2); */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container.disabled .checkmark:before {
    /* border-width: 0 2px 2px 0 !important; */
    /* border: solid var(--icon_3); */
    background: var(--cta_4);
    left: 4.5px;
    top: 0.3px;
    width: 5.5px;
    height: 8px;
    /* border: solid var(--cta_2); */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
