.prize__card {
	padding: 16px 0;
	width: 100%;
	min-width: 86px;
	max-width: 86px;
	display: flex;
	gap: 8px;
	flex-direction: column;
	align-items: center;
	border: 2px solid rgba(209, 212, 211, 1);
	border-radius: 16px;
	background: radial-gradient(48.66% 88.24% at 50% 50%, #2C2A24 0%, #2C2A24 100%);
	text-align: center;
	overflow: hidden;

	// @media (min-width: 768px) {
	// 	padding: 22px 14px;
	// 	max-width: 174px;
	// 	gap: 8px;
	// }

	&>div:first-child {
		display: none;
		border-radius: 24px;
		order: 0;
	}

	&>div:nth-child(2) {
		font-size: 11px;
		line-height: 16px;
		color: #fff;
		text-transform: uppercase;
		order: 3;
		max-width: 124px;
		min-height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (min-width: 768px) {
			font-size: 10px;
			line-height: 14px;
		}
	}

	&>div:last-child {
		position: relative;
		order: 2;
		z-index: 5;

		// @media (min-width: 768px) {
		// 	min-width: 142px;
		// 	min-height: 142px;
		// }
	}
}