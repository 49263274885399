.list ul,
.list ol {
    padding-left: 20px;
}

.list ul{
    list-style: disc;
}

.list ol{
    list-style: decimal;
}

.description a {
    display: block;
    margin-top: 24px;
    text-decoration: underline;
}