.wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* Customize the scrollbar width */
}

.wrapper::-webkit-scrollbar-track {
    /* Customize the scrollbar track */
    border-radius: 20px;
    background: var(--cat_2);
}

.wrapper::-webkit-scrollbar-thumb {
    /* Customize the scrollbar thumb */
    background: var(--cta_1);
    border-radius: 20px;
    border: 3px solid var(--cat_2);
}